#carousel {
  height: 100vh;
  background-color: #000;
}

#title {
  position: absolute;
  top: 30%;
  width: 100%;
  filter: drop-shadow(10px 10px 5px #c0a1e0);
  text-align: center;
  color: white;
  font-size: 10vw;
  z-index: 2;
}

.carousel-button {
  width: 300px;
  height: 100px;
  object-fit: contain;
  background-position: center center;
}

.carousel-button:hover {
  filter:brightness(0.5);
}

.carousel-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  margin: 10px;
}

.carousel-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
}

.carousel-table-row a img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.carousel-footer {
  display: flex;
  gap: 10px;
}

.carousel-footer a {
  color: white;
  transition: 2s;
}

.carousel-footer a:hover {
  color: black;
  transition: 0.2s;
}

/* < CAROUSEL ARROWS > */
.swiper-button {
  display: flex;
  position: absolute;
  top: calc(50% - 12px);
  z-index: 10;
  cursor: pointer;
  color: white;
  transition: 0.2s;
}

.swiper-button:hover {
  color: black;
}

/* < CAROUSEL ARROWS SIZE > */
.swiper-button svg {
  width: 3rem;
  height: 3rem;
}
.image-swiper-button-prev {
  left: 5px;
}
.image-swiper-button-next {
  right: 5px;
}
.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
