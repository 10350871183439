#background {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(5px);
}

#background-bio {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#text {
  position: absolute;
  top: 50%;
  width: 100%;
  filter: drop-shadow(10px 10px 5px #c0a1e0);
  text-align: center;
  color: white;
}

#title {
  font-size: 10vw;
}

#text, #title {
  transition: 0.5s;
}

#text:hover, #title:hover {
  filter: drop-shadow(10px 10px 5px #333);
}

#subtitle {
  font-size: 5vw;
}

.links {
  position: fixed;
  width: 100vw;

  /* CENTER VERTICALLY */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  bottom: 0;
  margin-bottom: 50px;
  font-size: 2em;
}

#background:hover,
#title:hover,
#subtitle:hover {
  cursor: pointer;
}
