.bio {
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  /* CENTER VERTICALLY */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bio-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
  color: white;
  transition: 0.2s;
}

.bio-close:hover {
  color: black;
}

.bio-title {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}

.bio-text {
  text-align: center;
  color: white;

  /* CENTER HORIZONTALLY */
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.bio-footer {
  position: fixed;
  bottom: 0;
  margin-bottom: 50px;
  font-size: 2em;
}
